exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-agencyresubmit-tsx": () => import("./../../../src/pages/Agencyresubmit.tsx" /* webpackChunkName: "component---src-pages-agencyresubmit-tsx" */),
  "component---src-pages-chat-tsx": () => import("./../../../src/pages/Chat.tsx" /* webpackChunkName: "component---src-pages-chat-tsx" */),
  "component---src-pages-chats-tsx": () => import("./../../../src/pages/Chats.tsx" /* webpackChunkName: "component---src-pages-chats-tsx" */),
  "component---src-pages-congrats-tsx": () => import("./../../../src/pages/Congrats.tsx" /* webpackChunkName: "component---src-pages-congrats-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/Dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-forget-password-tsx": () => import("./../../../src/pages/ForgetPassword.tsx" /* webpackChunkName: "component---src-pages-forget-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-notification-tsx": () => import("./../../../src/pages/Notification.tsx" /* webpackChunkName: "component---src-pages-notification-tsx" */),
  "component---src-pages-order-tsx": () => import("./../../../src/pages/Order.tsx" /* webpackChunkName: "component---src-pages-order-tsx" */),
  "component---src-pages-orderdetails-chat-tsx": () => import("./../../../src/pages/Orderdetails_Chat.tsx" /* webpackChunkName: "component---src-pages-orderdetails-chat-tsx" */),
  "component---src-pages-orderdetails-tsx": () => import("./../../../src/pages/Orderdetails.tsx" /* webpackChunkName: "component---src-pages-orderdetails-tsx" */),
  "component---src-pages-profile-2-tsx": () => import("./../../../src/pages/Profile2.tsx" /* webpackChunkName: "component---src-pages-profile-2-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/Profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-register-1-tsx": () => import("./../../../src/pages/Register1.tsx" /* webpackChunkName: "component---src-pages-register-1-tsx" */),
  "component---src-pages-register-2-tsx": () => import("./../../../src/pages/Register2.tsx" /* webpackChunkName: "component---src-pages-register-2-tsx" */),
  "component---src-pages-register-3-tsx": () => import("./../../../src/pages/Register3.tsx" /* webpackChunkName: "component---src-pages-register-3-tsx" */),
  "component---src-pages-register-4-tsx": () => import("./../../../src/pages/Register4.tsx" /* webpackChunkName: "component---src-pages-register-4-tsx" */),
  "component---src-pages-register-5-tsx": () => import("./../../../src/pages/Register5.tsx" /* webpackChunkName: "component---src-pages-register-5-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/ResetPassword.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-setting-account-setting-tsx": () => import("./../../../src/pages/Setting/AccountSetting.tsx" /* webpackChunkName: "component---src-pages-setting-account-setting-tsx" */),
  "component---src-pages-setting-settinglayout-tsx": () => import("./../../../src/pages/Setting/Settinglayout.tsx" /* webpackChunkName: "component---src-pages-setting-settinglayout-tsx" */),
  "component---src-pages-setting-settingmenu-tsx": () => import("./../../../src/pages/Setting/Settingmenu.tsx" /* webpackChunkName: "component---src-pages-setting-settingmenu-tsx" */),
  "component---src-pages-sign-in-tsx": () => import("./../../../src/pages/SignIn.tsx" /* webpackChunkName: "component---src-pages-sign-in-tsx" */),
  "component---src-pages-status-tsx": () => import("./../../../src/pages/Status.tsx" /* webpackChunkName: "component---src-pages-status-tsx" */),
  "component---src-pages-support-2-tsx": () => import("./../../../src/pages/Support2.tsx" /* webpackChunkName: "component---src-pages-support-2-tsx" */),
  "component---src-pages-support-detail-tsx": () => import("./../../../src/pages/SupportDetail.tsx" /* webpackChunkName: "component---src-pages-support-detail-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/Support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-transections-tsx": () => import("./../../../src/pages/Transections.tsx" /* webpackChunkName: "component---src-pages-transections-tsx" */)
}

